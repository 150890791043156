import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { personalDetails } from "../Details";
import Typed from "typed.js";

const { img } = personalDetails;

const Home = () => {
  const typedElement = useRef(null);
  const typedInstance = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const options = {
      strings: ["Full Stack Developer", "Database Developer"],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    };

    typedInstance.current = new Typed(typedElement.current, options);

    return () => {
      typedInstance.current.destroy();
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 0.8 } },
  };

  const textVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { delay: 0.5, duration: 0.8 } },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.8, duration: 0.8 },
    },
  };

  return (
    <>
      <Helmet>
        <title>Anas Akil - Full Stack Developer</title>
        <meta name="description" content="Anas Akil's personal website. Full Stack Developer and Database Developer." />
        <meta name="keywords" content="Anas Akil, anasakil ,ANAS AKIL , Full Stack Developer, Database Developer, Web Development, Software Development" /> 
        <meta name="author" content="Anas Akil" />
      </Helmet>
      <motion.main
        className="container mx-auto max-width section md:flex justify-between items-center"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        style={{ position: "relative", zIndex: 1 }}
      >
        <motion.div variants={textVariants}>
          <motion.h1
            className="text-2xl text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold"
            variants={textVariants}
          >
            Hi! <br />
            My Name is:
            <br />
          </motion.h1>
          <motion.h1
            className="text-3xl bg-clip-text bg-[#9F5AF6] text-transparent md:text-4xl xl:text-5xl xl:leading-tight font-bold"
            variants={textVariants}
          >
            ANAS AKIL
          </motion.h1>
          <motion.h2
            className="text-2xl text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold"
            variants={textVariants}
          >
            <span ref={typedElement} />
          </motion.h2>
        </motion.div>
        <motion.div className="mt-6 md:mt-0" variants={imageVariants}>
          <motion.img
            className="w-1/2 md:ml-auto"
            src={img}
            loading="lazy"
            alt="anas akil"
            variants={imageVariants}
          />
        </motion.div>
      </motion.main>
    </>
  );
};

export default Home;
