import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Projects from "./Pages/Projects";
import Technologies from "./Pages/Technologies";
import VantaBackground from "./Components/VantaBackground";
import LottieAnimation from "./Components/LottieAnimation";
import { HelmetProvider } from "react-helmet-async";


function Main() {


  return (
    <>
      <Header />
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/test" element={<LottieAnimation />} />

      </Routes>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <HelmetProvider>
       <VantaBackground />
        <Main />
      </HelmetProvider>
      
    </Router>
  );
}

export default App;
