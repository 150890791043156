import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async"; 
import Experience from "../Components/Experience";
import DownloadButton from "../Components/DownloadButton";
import { personalDetails, ExperienceDetails, eduDetails } from "../Details";
import LottieAnimation from "../Components/LottieAnimation";
import animationData from "../assets/Animation - 1720648094381.json";
import animationData1 from "../assets/Animation - 1720651731152.json";

function About() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About Me - Anas Akil</title>
        <meta name="description" content="Learn more about Anas Akil, his experience, education, and personal details." />
        <meta name="author" content="Anas Akil" />
        <meta name="keywords" content="Anas Akil, About Anas Akil, Full Stack Developer, Experience, Education, Web Development, Software Development" />
      </Helmet>
      <main className="container mx-auto max-width pt-10 pb-20">
        <section className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div data-aos="fade-right">
            <h1 className="text-2xl text-white md:text-4xl xl:text-5xl xl:leading-tight font-bold">
              About Me
            </h1>
            <a
              href={personalDetails.cv}
              download={personalDetails.cv}
              className="mt-4 inline-block  bg-violet-800 hover:bg-white text-white hover:text-violet-800 font-bold py-2 px-4 rounded border border-white h-10 w-30"
            >
              <DownloadButton />
            </a>
            <p className="text-white py-8">{personalDetails.about}</p>
          </div>
          <br />
          <div data-aos="fade-left">
            <h1 className="text-2xl text-white md:text-4xl xl:text-5xl xl:leading-tight font-bold">
              Experience
            </h1>
            <div className="grid grid-cols-1 gap-4">
              {ExperienceDetails.map(
                (
                  { Position, Company, Location, Type, Duration, image },
                  index
                ) => (
                  <div
                    data-aos="fade-up"
                    data-aos-delay={index * 100}
                    key={index}
                    className="flex flex-col md:flex-row justify-between items-center"
                  >
                    <div className="w-full">
                      <Experience
                        position={Position}
                        company={Company}
                        location={Location}
                        duration={Duration}
                        className="text-white"
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <section data-aos="fade-up-left">
            <LottieAnimation animationData={animationData} />
          </section>
          <div data-aos="fade-left">
            <h1 className="text-2xl text-white md:text-4xl xl:text-5xl xl:leading-tight font-bold">
              Education
            </h1>
            <div className="grid grid-cols-1 gap-4">
              {eduDetails.map(
                ({ Position, Company, Location, Type, Duration }, index) => (
                  <div
                    data-aos="fade-up"
                    data-aos-delay={index * 100}
                    key={index}
                  >
                    <Experience
                      position={Position}
                      company={Company}
                      location={Location}
                      duration={Duration}
                    />
                  </div>
                )
              )}
            </div>
          </div>
          <section data-aos="fade-up-left">
            <LottieAnimation animationData={animationData1} />
          </section>
        </section>
      </main>
    </>
  );
}

export default About;
