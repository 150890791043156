import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const LottieAnimation = ({animationData}) => {
  return (
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: '80%', width: '80%' }}
      >
      </Player>
  );
};

export default LottieAnimation;
